body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 400px;
}


Additional or modified global styles

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  height: 100vh; /* Ensure body uses full height */
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1; /* Make root element take up remaining space */
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
}

.container {
  margin: 0 auto;
  padding: 20px;
}

.custom-button {
  margin: 16px 16px 16px 16px;
  padding: 12px 24px;
  background-color: #3f51b5;
  color: white;
  border-radius: 8px;
  border: none; /* Remove the default border */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.custom-button:hover {
  background-color: #303f9f; /* Darker shade for hover effect */
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 400px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: -10px;
  margin-bottom: 10px;
}

.table-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 100%; /* Ensure it takes the full width */
  flex: 1; /* Allow flex growth */
  display: flex;
  flex-direction: column;
}

.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  gap: 10px;
}

.pagination button {
  margin: 0 5px;
}

.pagination select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 38px;
  /* Match the height and padding of buttons */
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
